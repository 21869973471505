import React, { useState, useRef } from 'react'
import { Flex } from '../../Components/Common/Flex'
import { Card } from '../../Components/Common/Card'
import { Button } from '../../Components/Common/Button'
import { Text } from '../../Components/Common/Text'
import { Spinner } from '../../Components/Common/Spinner'
import { Icon } from '../../Components/Common/Icon'
import { Toast } from 'primereact/toast'
import axios from 'axios'
import FileUpload from './Components/FileUpload.jsx'
import DownloadCard from '../ExcelProcessor/Components/DownloadCard'
import FloatLabelTextInput from '../../Components/Common/FloatLabelTextInput'
import ReportTable from './Components/ReportTable'

const Zucchetti = () => {
  const [fileCarta, setFileCarta] = useState(null)
  const [fileMovimenti, setFileMovimenti] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [giorniTolleranza, setGiorniTolleranza] = useState(3)
  const [reportData, setReportData] = useState()
  const [, setDownloadLink] = useState('#')
  const [isDownloadReady, setIsDownloadReady] = useState(false)
  const toast = useRef(null)
  const refUploadCartaViva = useRef(null)
  const refUploadFileDue = useRef(null)
  const origin = window?._env_?.API_BASE_URL

  const handleFileCartaVivaChange = (e) => setFileCarta(e.target.files[0])
  const handleFileDueMovimenti = (e) => setFileMovimenti(e.target.files[0])

  const handleProcess = async () => {
    setIsProcessing(true)
    try {
      if (!fileCarta || !fileMovimenti) {
        toast.current.show({ severity: 'error', summary: 'Errore', detail: 'Caricare entrambi i file' })
        return
      }
      const formData = new FormData()
      formData.append('fileCarta', fileCarta)
      formData.append('fileMovimenti', fileMovimenti)
      formData.append('giorniTolleranza', giorniTolleranza)
      const res = await axios.post(`${origin}/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      if (res) {
        setReportData(res.data.resData)
        setDownloadLink(`${origin}/output.xlsx`)
        toast.current.show({ severity: 'success', summary: 'Successo', detail: res.data.message })
        setIsDownloadReady(true)
      }
    } catch (error) {
      console.error('Errore durante il caricamento dei file:', error)
    } finally {
      setIsProcessing(false)
    }
  }
  return (
    <Flex fw js>
      <Toast ref={toast} />
      <Text title upCase bold size={34} value='Riconciliazione VivaWallet Zucchetti' />
      <Flex jb fw row style={{ width: '50%', marginTop: '2em', marginBottom: '1em' }}>
        <FileUpload label='Estratto carta Viva' file={fileCarta} onFileChange={handleFileCartaVivaChange} refUpload={refUploadCartaViva} />
        <FileUpload label='File Zucchetti' file={fileMovimenti} onFileChange={handleFileDueMovimenti} refUpload={refUploadFileDue} />
      </Flex>
      {fileCarta && fileMovimenti && (
        <Flex ab fw row style={{ width: '50%', gap: '1em', marginTop: '0em', marginBottom: '1em' }}>
          <Card style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Text value='Parametri' style={{ fontSize: 20, fontWeight: 600, marginBottom: '1em' }} />
            <Flex fw js row style={{ gap: '1em' }}>
              <FloatLabelTextInput label='Giorni Tolleranza' value={giorniTolleranza} onChange={(e) => setGiorniTolleranza(e.target.value)} />
              {giorniTolleranza && <Icon name='check' size={12} color='green' style={{ fontWewight: 900 }} />}
            </Flex>
          </Card>
          {!isProcessing
            ? (
              <Card style={{ width: '100%' }}>
                <Text value='Esecuzione' style={{ fontSize: 20, fontWeight: 600, marginBottom: '1em' }} />
                <Button
                  primary
                  label='Processa'
                  icon='xls-2'
                  id='processButton'
                  onClick={handleProcess}
                  style={{ width: '180px' }}
                />
              </Card>
              )
            : (
              <Spinner />
              )}
        </Flex>
      )}
      {isDownloadReady && (
        <>
          <Flex fw as jb row style={{ width: '50%', gap: '1em', marginBottom: '2em' }}>
            <DownloadCard
              downloadLinks={[`${origin}/file_carta_viva.xlsx`, `${origin}/file_zucchetti.xlsx`]}
              title='Download Excel Processati'
            />
          </Flex>
          <Flex fw as jb row style={{ width: '50%', gap: '1em', marginBottom: '2em' }}>
            <Card style={{ width: '100%' }}>
              <ReportTable reportData={reportData} />
            </Card>
          </Flex>
        </>
      )}
    </Flex>
  )
}

export default Zucchetti
