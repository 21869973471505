import { useState } from 'react'
import { Outlet, RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { useMount } from 'react-use'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ProtectedRoute, ErrorHandler } from './Pages'
import { FC } from './Services'
import AuthProvider, { useAuth } from './Provider/AuthProvider'
import { Flex, Spinner, Background } from './Components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Login from './Pages/Login'
import ExcelProcessor from './Pages/ExcelProcessor/ExcelProcessor'
import Zucchetti from './Pages/Zucchetti'

export const queryClient = new QueryClient()

function AppSetup () {
  const { user: storedUser, onLoginJWT } = useAuth()
  const [loading, setLoading] = useState(true)

  useMount(async () => {
    if (FC.authenticated && !storedUser?.accessToken) {
      setLoading(false)
      return true
    }
    storedUser?.accessToken && await onLoginJWT(storedUser?.accessToken)
    setLoading(false)
  })

  return (
    <Background>
      <ReactQueryDevtools initialIsOpen={false} />
      {loading ? <Flex fw fh><Spinner /></Flex> : <Outlet />}
    </Background>
  )
}

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorHandler />,
    children: [
      {
        element: <AppSetup />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              { path: '/', index: true, element: <ExcelProcessor /> },
              { path: '/zucchetti', index: true, element: <Zucchetti /> },
              { path: '*', index: true, element: <Navigate to='/report/list' /> }
            ]
          }
        ]
      },
      { path: '/login', element: <Login /> }
    ]
  }
]
)

function App () {
  return (
    <Background>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Background>
  )
}
export default App
