import React, { useState, useRef } from 'react'
import { Flex } from '../../Components/Common/Flex'
import { Card } from '../../Components/Common/Card'
import { Button } from '../../Components/Common/Button'
import { Text } from '../../Components/Common/Text'
import { Toast } from 'primereact/toast'
import FileUpload from './Components/FileUpload'
import ParametersForm from './Components/ParametersForm'
import SqlModal from './Components/SqlModal'
import DownloadCard from './Components/DownloadCard'
import axios from 'axios'

const ExcelProcessor = () => {
  const [fileCarta, setFileCarta] = useState(null)
  const [fileMovimenti, setFileMovimenti] = useState(null)
  const [parameters, setParameters] = useState({
    nomeColonnaImporto: '',
    nomeColonnaImporto2: '',
    nomeColonnaData: '',
    giorniTolleranza: '',
    colonneData: '',
    checked: false
  })
  const [sqlOutput, setSqlOutput] = useState('')
  const [downloadLink, setDownloadLink] = useState('#')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isTextCopied, setIsTextCopied] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const origin = window?._env_?.API_BASE_URL
  const refUploadCarta = useRef(null)
  const refUploadMovimenti = useRef(null)
  const sqlOutputRef = useRef(null)
  const toast = useRef(null)

  const handleFileCartaChange = (e) => setFileCarta(e.target.files[0])
  const handleFileMovimentiChange = (e) => setFileMovimenti(e.target.files[0])

  const handleProcess = async () => {
    setIsProcessing(true)
    try {
      if (!fileCarta || !fileMovimenti) {
        toast.current.show({ severity: 'error', summary: 'Errore', detail: 'Caricare entrambi i file' })
        return
      }
      const formData = new FormData()
      formData.append('fileCarta', fileCarta)
      formData.append('fileMovimenti', fileMovimenti)
      formData.append('nomeColonnaImporto', parameters.nomeColonnaImporto)
      if (parameters.checked) {
        formData.append('nomeColonnaImporto2', parameters.nomeColonnaImporto2)
      }
      formData.append('nomeColonnaData', parameters.nomeColonnaData)
      formData.append('giorniTolleranza', parameters.giorniTolleranza)
      formData.append('colonneData', JSON.stringify(parameters.colonneData))

      const res = await axios.post(`${origin}/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      if (res) {
        setSqlOutput(res.data.sqlOutput)
        setDownloadLink(`${origin}/output.xlsx`)
        toast.current.show({ severity: 'success', summary: 'Successo', detail: res.data.message })
      }
    } catch (error) {
      console.error('Errore durante il caricamento dei file:', error)
    } finally {
      setIsProcessing(false)
    }
  }

  const handleCopy = () => {
    if (sqlOutputRef.current.value) {
      const textToCopy = sqlOutputRef.current
      textToCopy.select()
      document.execCommand('copy')
      setIsTextCopied(true)
      setTimeout(() => {
        setIsTextCopied(false)
      }, 2000)
    }
  }

  return (
    <Flex fw js>
      <Toast ref={toast} />
      <Text title upCase bold size={34} value='Riconciliazione Carta' />
      <Flex jb fw row style={{ width: '50%', gap: '1em', marginTop: '2em', marginBottom: '1em' }}>
        <FileUpload label='Estratto carta di credito' file={fileCarta} onFileChange={handleFileCartaChange} refUpload={refUploadCarta} />
        <FileUpload label='Movimenti AS400' file={fileMovimenti} onFileChange={handleFileMovimentiChange} refUpload={refUploadMovimenti} />
      </Flex>
      <Flex jb fw row style={{ width: '50%', gap: '1em', marginTop: '2em', marginBottom: '1em' }}>
        <ParametersForm
          parameters={parameters}
          setParameters={setParameters}
          isProcessing={isProcessing}
          handleProcess={handleProcess}
        />
      </Flex>
      <Flex fw as jb row style={{ width: '50%', gap: '1em', marginTop: '2em', marginBottom: '2em' }}>
        <Card style={{ width: '60%', minHeight: '130px' }}>
          <Flex as>
            <Text style={{ marginBottom: '1em' }} size={18} bold value='SQL Di Aggiornamento' />
            <Button primary label='Visualizza' icon='see' id='processButton' onClick={() => setIsModalVisible(true)} />
            <SqlModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              sqlOutput={sqlOutput}
              sqlOutputRef={sqlOutputRef}
              handleCopy={handleCopy}
              isTextCopied={isTextCopied}
            />
          </Flex>
        </Card>
        <DownloadCard downloadLink={downloadLink} title='Scarica Excel Processato' />
      </Flex>
    </Flex>
  )
}

export default ExcelProcessor
