import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'

const ReportTable = ({ reportData }) => {
  const {
    initialRowCountCarta, initialRowCountMovimenti, finalRowCountCarta,
    finalRowCountMovimenti, matchingRowsCarta, matchingRowsMovimenti
  } = reportData

  const cartaData = [
    {
      description: 'Righe Iniziali File Carta Viva',
      count: initialRowCountCarta
    },
    {
      description: 'Righe Dopo Elaborazione File Carta Viva',
      count: finalRowCountCarta
    }
  ]

  const zucchettiData = [
    {
      description: 'Righe Iniziali File Zucchetti',
      count: initialRowCountMovimenti
    },
    {
      description: 'Righe Dopo Elaborazione File Zucchetti',
      count: finalRowCountMovimenti
    }
  ]

  const matchComparisonData = [
    {
      description: 'Righe Con Match (CHECK = V)',
      valoreCartaViva: matchingRowsCarta,
      valoreFileZucchetti: matchingRowsMovimenti
    }
  ]

  const statusTemplate = (rowData, type) => {
    let isEqual

    if (type === 'carta') {
      isEqual = initialRowCountCarta === finalRowCountCarta
    } else if (type === 'zucchetti') {
      isEqual = initialRowCountMovimenti === finalRowCountMovimenti
    } else if (type === 'comparison') {
      isEqual = rowData.valoreCartaViva === rowData.valoreFileZucchetti
    }

    return (
      <Tag
        severity={isEqual ? 'success' : 'warning'}
        value={isEqual ? 'OK' : 'WARNING'}
      />
    )
  }

  return (
    <div className='card'>
      <h3>Dati File Carta Viva</h3>
      <DataTable value={cartaData}>
        <Column field='description' header='Descrizione' />
        <Column field='count' header='Valore' />
        <Column
          header='Stato'
          body={(rowData) => statusTemplate(rowData, 'carta')}
        />
      </DataTable>

      <h3>Dati File Zucchetti</h3>
      <DataTable value={zucchettiData}>
        <Column field='description' header='Descrizione' />
        <Column field='count' header='Valore' />
        <Column
          header='Stato'
          body={(rowData) => statusTemplate(rowData, 'zucchetti')}
        />
      </DataTable>

      <h3>Confronto Righe con Match</h3>
      <DataTable value={matchComparisonData}>
        <Column field='description' header='Descrizione' />
        <Column field='valoreCartaViva' header='Valore Carta Viva' />
        <Column field='valoreFileZucchetti' header='Valore File Zucchetti' />
        <Column header='Stato' body={statusTemplate} />
      </DataTable>
    </div>
  )
}

export default ReportTable
