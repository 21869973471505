import React from 'react'
import { Card } from '../../../Components/Common/Card'
import { Flex } from '../../../Components/Common/Flex'
import { Button } from '../../../Components/Common/Button'
import { Text } from '../../../Components/Common/Text'
import { Icon } from '../../../Components/Common/Icon'

const FileUpload = ({ label, file, onFileChange, refUpload }) => (
  <Card style={{ width: '49%', cursor: 'pointer' }}>
    <Flex as fw js style={{ gap: '1em' }}>
      <label style={{ fontSize: 20, fontWeight: 600 }} htmlFor='fileUpload'>{label}</label>
      <Flex row fw js style={{ gap: '1em' }}>
        <Button icon='sheet-new' label='Carica il file' primary onClick={() => refUpload.current.click()} />
        <input style={{ display: 'none' }} type='file' ref={refUpload} accept='.xlsx, .xls' onChange={onFileChange} />
        <Text title upCase size={8} bold value={file?.name || ''} />
        {file?.name && <Icon name='check' size={12} color='green' style={{ fontWewight: 900 }} />}
      </Flex>
    </Flex>
  </Card>
)

export default FileUpload
