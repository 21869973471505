import React from 'react'
import { Card } from '../../../Components/Common/Card'
import { Flex } from '../../../Components/Common/Flex'
import { Text } from '../../../Components/Common/Text'
import { Button } from '../../../Components/Common/Button'
import { useLocation } from 'react-router-dom'

const DownloadCard = ({ downloadLinks, title }) => {
  const currentURL = useLocation()
  const specificLocation = currentURL.pathname

  return (
    <Card style={{ width: specificLocation === '/zucchetti' ? '100%' : '45%', minHeight: '130px' }}>
      <Flex as>
        <Text style={{ marginBottom: '1em' }} value={title} size={18} bold />
        <Flex as row>
          {Array.isArray(downloadLinks) && downloadLinks.length > 0
            ? (
                downloadLinks.map((link, index) => (
                  <a
                    key={index}
                    id={`downloadLink-${index}`}
                    style={{ textDecoration: 'none', marginRight: '1em' }}
                    href={link}
                    download={`output_${index + 1}.xlsx`}
                  >
                    <Button icon='sheet-new' label={`Download File ${index === 0 ? 'Viva' : 'Zuccheti'}`} primary />
                  </a>
                ))
              )
            : (
              <a id='downloadLink' style={{ textDecoration: 'none' }} href={downloadLinks} download='output.xlsx'>
                <Button icon='sheet-new' label='Download Excel' primary />
              </a>
              )}
        </Flex>
      </Flex>
    </Card>
  )
}

export default DownloadCard
